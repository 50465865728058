
import baseConstants from '~/store/base/-constants'

export default {
  name: 'AtomMobileButtonCategories',
  data() {
    return {
      isMobileView: typeof window !== 'undefined' ? window.innerWidth < 901 : false
    }
  },
  computed: {
    hasPinsCategories() {
      return this.$store.state.base?.categoriesPinsMobile || false
    },
    pinCategories() {
      return this.$store.getters?.getActiveProjectSettings?.building?.pinsData?.categories || []
    },
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    toggleCategoriesPinsMobile() {
      this.$store.dispatch(
        baseConstants.withNamespace(baseConstants.action.TOGGLE_CATEGORIES_PINS_MOBILE)
      )
    },
    resizeListener(e) {
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 901 : false
    }
  }
}
